.twitch .twitch-button {
    position: absolute;
    top: 43%;
    box-shadow: none;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    background-color: transparent;
  }
  .twitch .twitch-button.right {
    right: 0%;
  }
  .react-stacked-center-carousel {
    padding: 20px 0;
    height: 350px !important;
  }
  
  .twitch-card {
    transition: all 300ms ease;
    cursor: pointer;
    width: 100%;
    height: 300px;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .twitch-card:hover {
    transform: scale(1.05);
  }
  
  .react-stacked-center-carousel-slide-0 .twitch-card {
    cursor: default;
  }
  
  .react-stacked-center-carousel-slide-0 .twitch-card:hover {
    transform: none;
  }
  
  .fill {
    width: 100%;
    height: 100%;
  }
  
  .twitch-card .card-overlay {
    user-select: none;
    position: absolute;
    background-color: #00000099;
    transition: all 300ms ease;
  }
  
  .twitch-card:hover .card-overlay {
    background-color: #0000002d;
  }
  
  .react-stacked-center-carousel-slide-0 .card-overlay {
    background-color: #00000099 !important;
  }
  
  .twitch-card .cover-image {
    object-fit: cover;
    border-radius: 25px;
  }
  
  .twitch-card .cover {
    position: absolute;
    transition: opacity 300ms ease;
  }
  .react-stacked-center-carousel-slide-0 .twitch-card .cover {
    transition: opacity 300ms ease, z-index 0ms 300ms;
  }
  
  .twitch-card .cover.on {
    opacity: 1;
    z-index: 1;
  }
  
  .twitch-card .cover.off {
    opacity: 0;
    z-index: -1;
  }
  
  .twitch-card .detail {
    display: flex;
  }
  .twitch-card .video {
    width: 70%;
  }
  .twitch-card .discription {
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  
  .twitch-card .avatar {
    background-color: #ff6161;
  }
  
  .twitch-card p {
    cursor: auto;
    padding: 0 12px;
    font-size: 14px;
    line-height: 150%;
  }

  /* // saad css */
.card-overlay.fill {
  border-radius: 25px;
}

.detail.fill video {
  border-radius: 25px 0 0 25px;
  object-fit: cover !important;
}
.video-sidebar p img {
  width: 30px !important;
  height: 30px !important;
}
.video-sidebar p figure em{
right: 3px !important;
}